import React, {useState, useEffect} from "react";
import "../../assets/css/component/modal/searchModal.css";
import { CSSTransition } from "react-transition-group";
import { UseModalContext } from "../../context/UseModalContext";
import { isMobile } from 'react-device-detect';
import { UseUserContext } from '../../context/UserContext';
import CDN from "../../classes/CDN"
import axios from "axios";
import CloseButton from "../presentation/CloseButton";

const SearchModal = () => {
  const { variaveisFornecedor } = UseUserContext();
  const { search, setSearch, setPagecnt } = UseModalContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [matchingFiles, setMatchingFiles] = useState([]);

  const { ordemPaginas } = variaveisFornecedor; //sequência das páginas

  var cdn = new CDN(variaveisFornecedor.id_fornecedor);
  var {getPage} = cdn.pages(variaveisFornecedor.id)

  const handleSearchModal = () => {
    setSearch(false);
  };

  const handleChangeInput = (e) => {
    // console.log(e.target.value);
    setSearchTerm(e.target.value);
  }

  const searchFiles = async () => {
    if(searchTerm!=="" && searchTerm.length>2){
      let url = new URL(`${variaveisFornecedor.url.href}/search`)

      url.search = new URLSearchParams({
        searchTerm,
        supplier_id: variaveisFornecedor.id_fornecedor,
        catalog_id: variaveisFornecedor.id
        //cliente: cliente
      })
      let response;
      try {
        response = await axios.get(url)
        setMatchingFiles(response.data)
      } catch (error) {
        console.log(error.response.data)
      }
    }
  };

  const shareWithSearch = ()=> {
    let url = new URL(window.location.href)
    
    url.search = new URLSearchParams({
      pesquisa: searchTerm
    })

    let text = `Confira estes produtos da ${variaveisFornecedor.nome_fornecedor} encontrados na minha pesquisa !`

    window.open(`https://api.whatsapp.com/send?text=${text}%0D%0A${url}`)
  }

  useEffect(() => {
    searchFiles();
    // eslint-disable-next-line
  }, [searchTerm]);

  const handlePageGo = (imgnumber) => {
    try {
      var pagina = Object.values(ordemPaginas).find(page => page === `${imgnumber}.webp`).split('.')[0];
      setPagecnt(pagina);
      if(isMobile) {
        setSearch(false);
      }
    } catch (error) {
      console.log("Error setting page number")
    }
  }

  const getCurrentPage = (imgNumber) => getPage([imgNumber])

  return (
    <CSSTransition in={search} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
      <div className="modal-s" onClick={handleSearchModal}>
        <div className="modal-s-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-s-header">
            <div className="modal-s-title">
              <span>Busca de produtos</span>
            </div>
            <CloseButton handleClick={handleSearchModal}/>
          </div>
          <div className="modal-s-body">
            <div className="modal-s-content-div">
              <input
                className="modal-s-content-input"
                type="text"
                value = {searchTerm}
                placeholder="Por favor, digite o nome ou tipo do produto"
                onChange={handleChangeInput}
              />
            </div>
            {matchingFiles.length > 0 ?
            <>
              <div className="flex justify-center">
                <button className="w-4/5 mb-4 px-4 py-0.5 rounded justify-self-center bg-gray-700 text-white text-center" onClick={shareWithSearch}>Compartilhar Pesquisa</button>
              </div>
              
              {(
                isMobile ? (
                  matchingFiles.map((imgnumber,index) => (
                      <div key={index} className="p-2 cursor-pointer" onClick={() => handlePageGo(imgnumber)}>
                        <div className="flex w-full">
                          <div className="w-full h-1/2">
                            <img src={getCurrentPage(imgnumber)} alt={`Page ${imgnumber}`} />
                          </div>
                        </div>
                        <div className="w-2/5 m-auto text-center bg-gray-700 text-white px-2 py-1 rounded-lg">
                          Página {imgnumber}
                        </div>
                      </div>
                    ))
                  )
                  :
                  (()=> {
                    let lastPagePair = null
                    return matchingFiles.map((imgnumber, index)=> {
                      if (imgnumber % 2===0) {
                        if (lastPagePair === `${imgnumber - 1} - ${imgnumber}`) {
                          return null
                        }
                        lastPagePair = `${imgnumber - 1} - ${imgnumber}`
                        return (
                          <div key={index} className="p-2 cursor-pointer" >
                            <div className="flex w-full" onClick={() => handlePageGo(imgnumber - 1)}>
                              <div className="w-1/2">
                                <img src={getCurrentPage(imgnumber - 1)} alt={`Page ${imgnumber - 1}`} />
                              </div>
                              <div className="w-1/2">
                                <img src={getCurrentPage(imgnumber)} alt={`Page ${imgnumber}`} />
                              </div>
                            </div>
                            <div className="w-2/5 m-auto text-center bg-gray-700 text-white px-2 py-1 rounded-lg">
                              Páginas {imgnumber - 1} - {imgnumber}
                            </div>
                          </div>
                        )
                      } else {
                        if (lastPagePair === `${imgnumber} - ${imgnumber + 1}`) {
                          return null
                        }
                        lastPagePair = `${imgnumber} - ${imgnumber + 1}`
                        return (
                          <div key={index} className="p-2 cursor-pointer" >
                            <div className="flex w-full"  onClick={() => handlePageGo(imgnumber)}>
                              <div className="w-1/2">
                                <img src={getCurrentPage(imgnumber)} alt={`Page ${imgnumber}`} />
                              </div>
                              <div className="w-1/2">
                                <img src={getCurrentPage(imgnumber + 1)} alt={`Page ${imgnumber + 1}`} />
                              </div>
                            </div>
                            <div className="w-2/5 m-auto text-center bg-gray-700 text-white px-2 py-1 rounded-lg">
                              Página {imgnumber} - {imgnumber + 1}
                            </div>
                          </div>
                        )
                      }
                    })
                  })()
                )}
              </>
              :
              (
                <div className="modal-s-search-text">
                  <p>
                    Digite o nome ou o tipo do produto que está procurando e clique
                    em uma das imagens exibidas nos resultados
                  </p>
                </div>
              )}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default SearchModal;