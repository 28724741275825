import React, {useState, useEffect, useRef} from "react";
import "../../assets/css/component/modal/productModal.css";
import { CSSTransition } from "react-transition-group";
import { Carousel } from '@fancyapps/ui/dist/carousel/carousel.esm.js';
import '@fancyapps/ui/dist/carousel/carousel.css';

import ChangeQuantityButton from "../presentation/ChangeQuantityButton";
import SizeButton from "../presentation/SizeButton"
import ColorButton from "../presentation/ColorButton";
import FooterProductModalWithRules from "../presentation/FooterProductModalWithRules"
import { UseUserContext } from "../../context/UserContext";

let sizeCarousel, colorCarousel;

const ProductModal = ({ productModal, product, setProductModal, addToCart, setProduct }) => {
    const {rules} = UseUserContext().variaveisFornecedor
    const [productRef, setProductRef] = useState(null)
    const [selectedSize, setSelectedSize] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedCode, setSelectedCode] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const referenceSelect = useRef()
    const getCartProduct = ()=> {
      return {
        reference: product.reference,
        code: selectedCode || product.codes,
        id: product.id,
        description: product.description,
        page: product.image_number,
        color: selectedColor,
        size: selectedSize,
        price: product.price,
        quantity: quantity,
      }
    }

    const handleCodeSelect = (code) => {
      setSelectedCode(code);
      getRelativeProduct(code);
    };

    const handleColorSelect = (color) => {
      setSelectedColor(color)
      getRelativeProduct(color)
    };

    const handleSizeSelect = (size) => {
      setSelectedSize(size)
      getRelativeProduct(size)
    }

    const handleChangeQuantity = (c) => {
      if (quantity <= 1 && c < 0) {
        return
      }
      setQuantity(prev => prev + c)
    }

    const handleProductModal = () => {
      setProductModal(false);
    };

    const handleProductButton = () => {
      if(AllIsSelected()) {
        setProductModal(false);
        addToCart(getCartProduct());
      }
    };

    // Checa se todos os modais existentes estão selecionados
    const AllIsSelected = ()=> {
      if (product.sizes && !selectedSize) {
        return false
      }
      if (product.colors && !selectedColor) {
        return false
      }
      if (product.codes.split('|').length > 1) {
        if (!selectedCode) {
          return false
        }
        if (selectedCode === "DEFAULT") {
          return false
        }
      }
      return true
    }

    const clear = ()=> {
      setSelectedSize(null)
      setSelectedColor(null)
      setSelectedCode(null)
      setQuantity(1)
    }

    const getRelativeProduct = (selected)=> {
      let __product = productRef || product
      let rel = __product.relatives?.find(
        (rel)=> rel.value
          .split("|")
          .includes(selected)
        )
      if (rel) {
        for (let key in rel.changes) {
          if(__product[key] !== undefined) {
            __product[key] = rel.changes[key]
          }
        }
        if (productRef?.id !== __product.id) {
          setProductRef(__product)
          setProduct(__product)
        }
      }
    }

    useEffect(()=> {
      setTimeout(() => { // Tempo de espera para o usuário não ver os elementos desaparecendo
        if(productModal && product) { // Esta função reseta os carroséis, tamanhos, cores e quantidades.
          const options = { 
            infinite: true,
            Dots: true,
          };
          if (product.sizes) {
            const containerSize = document.querySelector("#sizeCarousel");
            sizeCarousel = new Carousel(containerSize, options);
          }
          if (product.colors) {
            const containerColor = document.querySelector("#colorCarousel");
            colorCarousel = new Carousel(containerColor, options);
          }
          if (!sizeCarousel) {
            return () => {
              colorCarousel.destroy();
            };
          }
          if (!colorCarousel) {
            return () => {
              sizeCarousel.destroy();
            };
          }
          return () => {
            sizeCarousel.destroy();
            colorCarousel.destroy();
          };
        }
        clear()
        setProductRef(null);
        if(referenceSelect.current) referenceSelect.current.value = "DEFAULT"
      }, 100);
    },[product, productModal]);

    return (
        <CSSTransition in={productModal} unmountOnExit timeout={{ enter: 0, exit: 1000 }}>
          {product ? (
            <div className="modal-pro" onClick={handleProductModal}>
            <div className="modal-pro-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-pro-header">
                  <div className="modal-pro-title">
                      <h1 className="px-4 text-center">{product.description}</h1>
                      <h1 className="px-4 text-right"><strong className="text-green-600">{product.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong></h1>
                  </div>
                </div>
                <div className="modal-pro-body">
                {product.codes.split('|').length > 1 ? <>
                  <div className="w-full mt-4">
                      <p className="text-center mb-2 text-lg">Referência</p>
                      <div className="w-full justify-center border-b-indigo-500">
                        <select className="px-4" defaultValue={"DEFAULT"} onChange={e => handleCodeSelect(e.target.value)} ref={e => referenceSelect.current = e}>
                          <option value={"DEFAULT"} disabled>
                            Selecione uma referência:
                          </option>
                        {product.codes.split('|').map((code, index)=> 
                          <option key={index} value={code}>
                            {code}
                          </option>
                          )}
                        </select>
                      </div>
                  </div> 
                  </> : null}
                  {product.sizes ? <>
                  <div className="w-full mt-4">
                      <p className="text-center mb-2 text-lg">Tamanho</p>
                      <div className="w-full flex justify-center border-b-indigo-500">
                        <div id="sizeCarousel" className="f-carousel w-full px-4 flex justify-center">
                          <div className="f-carousel__viewport">
                          {product.sizes.split('|').map((size, index)=> 
                            <div key={index} className="f-carousel__slide flex justify-center" style={{overflow: "hidden"}}>
                              <SizeButton size={size} selectedSize={selectedSize} onSelect={()=> handleSizeSelect(size)} />
                            </div>
                            )}
                          </div>
                        </div>
                      </div>
                  </div> 
                  </> : null}
                  {product.colors ? <>
                  <div className="w-full mt-4">
                      <p className="text-center mb-2 text-lg">Cor</p>
                      <div className="w-full flex justify-center">
                        <div id="colorCarousel" className="f-carousel w-full px-4 flex justify-center">
                          <div className="f-carousel__viewport">
                            {product.colors.split('|').map((color, index)=> 
                            <div key={index} className="f-carousel__slide flex justify-center overflow-hidden">
                              <ColorButton color={color} selectedColor={selectedColor} onSelect={()=> handleColorSelect(color)} />
                            </div>
                              )}
                          </div>
                        </div>
                      </div>
                  </div>
                  </> : null}
                </div>
                {
                rules && rules.productRules ? 
                  <FooterProductModalWithRules AllIsSelected={AllIsSelected} handleChangeQuantity={handleChangeQuantity} quantity={quantity} getCartProduct={getCartProduct} setProductModal={setProductModal} addToCart={addToCart} clear={clear} productModal={productModal}/>
                :
                  <div className="modal-pro-footer">
                    <div>
                      <button className={(AllIsSelected()) ? "allowed-button" : ""} onClick={handleProductButton}>Comprar</button>
                      <ChangeQuantityButton quantity={quantity} handleChangeQuantity={handleChangeQuantity}/>
                    </div>
                  </div>
                }
            </div>
            </div>) : <></>}
        </CSSTransition>
    );
};

export default ProductModal;