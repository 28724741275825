const ColorButton = ({ color, selectedColor, onSelect })=> {
    const isSelected = selectedColor === color;

    const backgroundColorClass = {
        rose: 'bg-rose-500',
        white: 'bg-white-500',
        blue: 'bg-blue-950',
        sky: 'bg-sky-500',
        cyan: 'bg-cyan-500',
        teal: 'bg-teal-500',
    }[color];

    return (
        <div className="flex flex-col items-center">
            <div
                className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer ${backgroundColorClass} ${
                    isSelected ? 'border-2 border-black' : 'border border-black-700'
                }`}
                onClick={() => onSelect(color)}
            ></div>
            <p 
                className="text-sm pt-2">{color}
            </p>
        </div>
    );
}

export default ColorButton;