import React, { useState, createContext, useContext } from "react";

const UserContext = createContext();

export const UserContextProvider = ({ children, data }) => {
    const [variaveisFornecedor, setVariaveisFornecedor] = useState(data);
    const variaveis = {
        variaveisFornecedor: variaveisFornecedor,
        setVariaveisFornecedor: setVariaveisFornecedor
    }

    return (
        <UserContext.Provider value={variaveis}>{children}</UserContext.Provider>
    );
};

export const UseUserContext = () => useContext(UserContext);

