import SmallButton from "./SmallButton"
import modalEmpty from "../../assets/img/caixa_vazia.png"
import { UseUserContext } from "../../context/UserContext"
import { openWhatsapp } from "../utils/whatsappAPI"

export default function EmptyCart() {
    const {ENTERPRISE_PHONE_NUMBER} = UseUserContext().variaveisFornecedor
    const repeatOrder = ()=> {
        let lastOrder = localStorage.getItem("lastOrder")

        if (lastOrder)
            openWhatsapp(ENTERPRISE_PHONE_NUMBER, lastOrder)
    }
    return (
        <div className="modal-c-body-empty">
            <img src={modalEmpty} alt='empty' />
            <p>Nenhum item adicionado</p>
            {localStorage.getItem("lastOrder") ? (
                <SmallButton onClick={repeatOrder}>Reenviar último pedido</SmallButton>
            ) : null}
        </div>
    )
}