import ErrorImage from "../../assets/img/404.png"
import SmallButton from "../presentation/SmallButton"

const CartErrorScreen = ({msg, handleClick})=> {
    return (
        <div className="h-full flex justify-center content-center flex-wrap gap-y-10" key={Math.random()}>
            <img src={ ErrorImage } alt="Imagem de Erro" />
            <p className="w-full text-center font-roboto">{msg ?? "Error"}</p> 
            <SmallButton onClick={handleClick}>Voltar</SmallButton>
        </div>
    )
}

export default CartErrorScreen