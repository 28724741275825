import { useLocation, useNavigate } from "react-router-dom"
import Icon404 from "../../assets/img/404.png"
import "../../assets/css/component/presentation/errorPage.css"


const Error404Page = ()=> {
    const path = useLocation().pathname
    const navigate = useNavigate()

    const handleClick = ()=> {
        navigate(-1)
    }

    return ( 
        <div className="error404">
            <h1>Erro 404 - Página não encontrada</h1>
            <img src={Icon404} alt="Erro 404: Página não encontrada" />
            <p>O caminho <strong>"{path}"</strong> não existe, certifique-se que você digitou a URL correta</p>
            <button onClick={handleClick}>Voltar para página anterior</button>
        </div>
    );
}

export default Error404Page;