const initialState = {
    cartItems: JSON.parse(localStorage.getItem("cartItems")) || [],
  };
  
const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const newItem = action.payload;
      const existingItemIndex = state.cartItems.findIndex(
        (item) =>
          item.id === newItem.id &&
          item.code === newItem.code &&
          item.color === newItem.color &&
          item.size === newItem.size
      );

      if (existingItemIndex !== -1) {
        return {
          ...state,
          cartItems: state.cartItems.map((item, index) =>
            index === existingItemIndex
              ? { ...item, quantity: item.quantity + action.payload.quantity }
              : item
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, newItem],
        };
      }
    case 'UPDATE_QUANTITY':
      const { id, code, color, size, quantity } = action.payload;
      const updatedCartItems = state.cartItems.map((item) => {
        if (
          item.id === id &&
          item.code === code &&
          item.color === color &&
          item.size === size
        ) {
          if(quantity === -1 && item.quantity === 1 ) {
            return item;
          }
          return {
            ...item,
            quantity: item.quantity + quantity, // Update quantity directly
          };
        }
        return item;
      });

      return {
        ...state,
        cartItems: updatedCartItems,
      };

    case 'REMOVE_FROM_CART':
      return {
        ...state,
        cartItems: state.cartItems.filter((item) => !(item.id === action.payload.id && item.reference === action.payload.reference && item.size === action.payload.size && item.color === action.payload.color)),
      };

    case 'CLEAR_CART':
      return {
        cartItems: []
      }
    default:
      return state;
  }
};

export default cartReducer;
  