import React, { useState } from "react";
import "../../assets/css/component/modal/cartModal.css";
import { CSSTransition } from "react-transition-group";
import { UseModalContext } from "../../context/UseModalContext";

import CartScreen from "../presentation/CartScreen";
import Checkout from "../presentation/Checkout";
import CloseButton from "../presentation/CloseButton";

const CartModal = ({ client }) => {
  
  const [checkout, setCheckout] = useState(false)
  const { cart, setCart } = UseModalContext();

  const handleCartModal = () => {
    setCart(false)
    setTimeout(()=> {
      setCheckout(false)
    }, 300)
  };

  return (
    <CSSTransition
      in={cart}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal-c" onClick={handleCartModal}>
        <div className="modal-c-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-c-header">
            <CloseButton handleClick={handleCartModal}/>
            <div className="modal-c-title">
                <span>Meus Pedidos</span>
            </div>
          </div>
          {
            !checkout ? 
              <CartScreen setCheckout={setCheckout}/>
            :
              <Checkout client={client} setCheckout={setCheckout}/>
          }
          
        </div>
      </div>
    </CSSTransition>
  );
};

export default CartModal;
