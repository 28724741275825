import { toBRL } from "../utils/currencyFormatter"
import ChangeQuantityButton from "./ChangeQuantityButton"
export default function ItemCartCard({item, handleChangeQuantity, handleRemoveItem}) {
    return (
        <div className="bg-white my-5 mx-3 rounded shadow-md flex flex-row relative">
            <span className="absolute -right-1 -top-1.5 text-sm py-0.5 p-1.5 text-red-500 rounded-full cursor-pointer bg-[#faebe8] transition-opacity" onClick={() => handleRemoveItem(item.id, item.reference, item.size, item.color)}>X</span>
            <div className="p-2 flex flex-col">
                <h4>{item.description}</h4>
                <p className="text-xs leading-5">{item.size && item.color ? `${item.size} | ${item.color}` : item.color || item.size }</p>
            </div>
            <div className="p-2 min-w-[6.5rem] flex flex-col">
                <h4 className="text-sm mb-2">{toBRL(item.quantity * item.price)}</h4>
                <ChangeQuantityButton quantity={item.quantity} handleChangeQuantity={handleChangeQuantity}/>
            </div>
        </div>
    )
}