import React from "react";
import { CSSTransition } from "react-transition-group";
import "../../assets/css/component/modal/welcomeModal.css";
import { UseUserContext } from '../../context/UserContext';
import CloseButton from "../presentation/CloseButton";

const WelcomeModal = (props) => {

    const { obr_cnpj, texto_cnpj, obr_nome, texto_nome, obr_telefone, texto_telefone } = UseUserContext().variaveisFornecedor;

    const handleSubmitForm = (e) => {
        e.preventDefault();
        const client = {}

        let nome = document.getElementById("nome")?.value
        let telefone = document.getElementById("telefone")?.value
        let cnpj = document.getElementById("cnpj")?.value

        if (obr_nome && nome) {
            client.nome = nome
        }

        if (obr_telefone && telefone) {
            client.telefone = telefone
        }

        if (obr_cnpj && cnpj) {
            client.cnpj = cnpj
        }
        if (!(Object.keys(client).length === 0)) {
            localStorage.setItem("client", JSON.stringify(client))
            props.setClient(client)
        }
        handleWelcomeModal()
    }

    const handleWelcomeModal = () => {
        props.setWelcome(false);
    };

    return (
        <CSSTransition in={props.welcome} unmountOnExit timeout={{ enter: 0, exit: 1000 }}>
            <div className="modal-welcome">
                <div className="modal-welcome-body" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-welcome-header">
                        <div className="modal-welcome-title">
                            <span>SEJA BEM-VINDO/A</span>
                        </div>
                        <CloseButton handleClick={handleWelcomeModal}/>
                    </div>
                    <div className="modal-welcome-context">
                        
                        <form onSubmit={handleSubmitForm}>
                            {obr_nome ? <>
                                <h3>{texto_nome}</h3>
                                <input className="modal-welcome-input" id="nome" type="text" placeholder="Digite seu nome" required/>
                            </> : null}
                            {obr_telefone ? <>
                                <h3>{texto_telefone}</h3>
                                <input className="modal-welcome-input" id="telefone" type="number" placeholder="Apenas Números" required/>
                            </> : null}
                            {obr_cnpj ? <>
                                <h3>{texto_cnpj}</h3>
                                <input className="modal-welcome-input" id="cnpj" type="number" placeholder="Apenas Números" required/>
                            </> : null}
                            <input className="modal-welcome-button" type="submit" value="Avançar"/>
                        </form>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default WelcomeModal;
