import React from "react";
import { isMobile } from "react-device-detect";

const CloseButton = ({handleClick})=> {
    return (
        <div onClick={handleClick} className="absolute w-6 h-6 top-3 right-3 flex items-center justify-center cursor-pointer opacity-40 border border-solid border-gray-500 rounded-full transition-opacity">
            <span className={isMobile ? "-mt-1" : ""}>x</span>
        </div>
    )
}

export default CloseButton