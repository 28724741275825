import React, { useEffect, useState } from "react";
import ThanksScreen from "./ThanksScreen";
import axios from "axios";
import { UseUserContext } from "../../context/UserContext";
import Loader from "./Loader";
import orderToString from "../utils/orderToString";
import isEmpty from "../utils/isEmpty";
import RoundedIcon from "./RoundedIcon";
import { openWhatsapp, getWhatsappURL } from "../utils/whatsappAPI";

const SendOrder = ({cartItems,client, clearCart}) => {
    const {variaveisFornecedor} = UseUserContext();
    const [loading, setLoading] = useState(true)
    const [countdownTime, setCountdownTime] = useState(null)
    // const total = cartItems.reduce((total, item) => total + (item.price * item.quantity), 0)
    const cliente = (client.nome || client.cnpj || client.telefone)
    const BR = "%0D%0A"; // Código De quebra de linha para enviar a mensagem do pedido pelo zap

    const order = `Catálogo ${variaveisFornecedor.nome_fornecedor ? variaveisFornecedor.nome_fornecedor : "Revista Digital"}${BR}Pedido de ${cliente || "Cliente Anônimo"}${BR}${BR}` + orderToString(cartItems)

    async function sendMail(cartItems, clientName) {
        const rule = variaveisFornecedor.rules?.targetAPI?.find(({action})=> action === "SEND_MAIL")
        if (rule) {
            try {
                const response = await axios.post(`${variaveisFornecedor.url.href}/sendMail`, {
                    data: {
                        cartItems, 
                        clientName,
                        receivers: rule.params.emails
                    },
                });
                console.log(response.data.message)
            } catch (error) {
                console.error(error)
            }
        }
    }

    // async function acessoApi(dados) {
    //     //acesso a api externa
    //     if(variaveisFornecedor.rules?.targetAPI) {
    //         try {
    //             let response = await axios.post(`${variaveisFornecedor.url.href}/aceso_api_externa`, {
    //                 data: {
    //                     dados, cartItems,client
    //                 },
    //             });
    //             console.log(response)
    //         } catch (error) {
    //             console.error(error)
    //         }
    //     }
    // }

    async function persistOrder(order) {
        try {
            const informacoesPedido = {
                nomeCliente: cliente,
                itensVendidos: cartItems,
                dadosIP: variaveisFornecedor.dadosIp,
                idCatalogo: variaveisFornecedor.id,
                idFornecedor: variaveisFornecedor.id_fornecedor,
            }
            var url = variaveisFornecedor.url.href;
            const response = await axios.post(`${url}/salva_pedidos_enviados_zap`, {
                data: {
                    infoCliente: informacoesPedido
                },
            });
            console.log(response.data.message)
            
            // Persist on localStorage
            localStorage.setItem("lastOrder", order)
            localStorage.removeItem("cartItems")
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=> {
        if (countdownTime !== null) {
            if (countdownTime > 0) {
                setTimeout(()=> setCountdownTime(countdownTime - 1), 700);
            } else {
                openWhatsapp(variaveisFornecedor.ENTERPRISE_PHONE_NUMBER, order)
                setLoading(false)
            }
        }
    // eslint-disable-next-line
    }, [countdownTime])

    useEffect(()=> {
        if (!isEmpty(cartItems)) {
            async function sendOrder() {
                // await acessoApi({
                //     'total': total,
                //     'descrição': order,
                //     'api': variaveisFornecedor
                // })
                Promise.all([
                    await sendMail(cartItems, cliente),
                    await persistOrder(order)
                ])
                setCountdownTime(3)
            }
            sendOrder()
        }
        return ()=> {
            if (!isEmpty(cartItems))
                clearCart()
        }
        // eslint-disable-next-line
    },[])

    return ( 
        !loading || isEmpty(cartItems) ?
            <ThanksScreen>
                <button onClick={()=> navigator.clipboard.writeText(order.replaceAll(BR,'\n'))} >
                    <RoundedIcon src="../../assets/img/copy-icon.svg" alt="Logo de Copiar" textHover={"Copiar"} size={"2.5rem"}/>
                </button>
                <a href={getWhatsappURL(variaveisFornecedor.ENTERPRISE_PHONE_NUMBER, order)} target="_blank" rel="noreferrer">
                    <RoundedIcon src="../../assets/img/whatsapp-icon.svg" alt="Logo do Whatsapp" textHover={"Enviar para o Whatsapp"}/>
                </a>
            </ThanksScreen>
        :
            <Loader text={countdownTime === null ? "Processando o seu pedido ..." : `Você será redirecionado para o whatsapp em ${countdownTime}...`}/>
    )
}

export default SendOrder;