import React, {useContext, useState, createContext} from "react";
const cntContext = createContext();

export const CNTContextProvider = ({children}) => {
    const [cnt, setCnt] = useState(1);
    const pagecnt = {
        cnt: cnt,
        setCnt: setCnt,
    };

    return (
        <cntContext.Provider value={pagecnt}>{children}</cntContext.Provider>
    );
}

export const UseCntContext = () => useContext(cntContext);