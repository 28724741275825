import ThanksImage from "../../assets/img/thanks.png"

const ThanksScreen = ({children})=> {
    return (
        <div className="h-full flex justify-center content-center flex-wrap gap-y-10 gap-x-20">
            <img className="px-5" src={ ThanksImage } alt="Boneca com vários corações ao seu redor" />
            <h1 className="w-full text-center text-xl">Obrigado por comprar conosco !</h1>
            <h3 className="w-full h-0 text-center text-lg">Enviar pedido:</h3>
            {children}
        </div>
    )
}

export default ThanksScreen