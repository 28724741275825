import { Link } from "react-router-dom";

const ClickableMask = ({ left, top, width, height, link, area, paginas=1, direita=0 })=> {
    if (!area) {
        return null
    }

    let url = new URL(link)

    //se a página estiver na direita=1, inicia na metade da página
    let inicio = (direita) ? area?.offsetWidth/2 : 0
    const larguraPagina = area?.offsetWidth/paginas;
    //const largura = width/paginas;
    const largura = (width*larguraPagina)/100;

    return (
        <Link to={url.href} target={url.host === window.location.host ? "_self" : "_blank"} rel="noreferrer"
            style={{
                width: `${largura}px`,
                height: height,
                position: "absolute",
                left: inicio + ((left*larguraPagina)/100),//calcula o left pela largura da página
                top: top,
                background: `transparent`,
                opacity: 0.5
            }} 
        > </Link>
    )
}

export default ClickableMask;