import React from "react";
import { UseUserContext } from "../../context/UserContext";
import RulesValidation from "./RulesValidation";
import SendOrder from "./SendOrder";
import { clearCart } from "../../store/actions/cartAction";
import { connect } from "react-redux";
import isEmpty from "../utils/isEmpty";

const Checkout = ({cartItems, clearCart, client, setCheckout})=> {
  const {rules} = UseUserContext().variaveisFornecedor;
  const handleClick = ()=> setCheckout(false)

  if (rules && rules.checkoutRules) {
    const validationResults = RulesValidation(cartItems, handleClick, rules.checkoutRules)

    return ( 
      !isEmpty(validationResults) ? 
        validationResults[0] 
      : 
        <SendOrder cartItems={cartItems} client={client} clearCart={clearCart} />
    )
  }

  return <SendOrder cartItems={cartItems} client={client} clearCart={clearCart}/>
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.cartItems,
});

export default connect(mapStateToProps, { clearCart })(Checkout);