import React from "react"
import CartErrorScreen from "../error/CartErrorScreen"
import { toBRL } from "../utils/currencyFormatter"

const RulesValidation = (cartItems, handleClick, rules)=> {
    if (cartItems.length !== 0) {
        var invalidItems = rules.map((rule)=> {
            switch (rule.action) {
                case "CHECK_QUANTITY_OF_EACH_ITEM":
                    let cartItemsGrouped = Object.groupBy(cartItems, ({reference})=> reference)
                    let invalidItems = Object.values(cartItemsGrouped).filter((group)=> group.reduce((total, item) => total + item.quantity, 0) < rule.params.min)

                    if (invalidItems.length !== 0) 
                        return <CartErrorScreen msg={`Você deve comprar no mínimo ${rule.params.min} produtos de cada`} handleClick={handleClick} />
                    break;
                case "MIN_VALUE":
                    let total = cartItems.reduce((total, item) => total + (item.price * item.quantity), 0);
                    
                    if (total < rule.params.min) 
                        return <CartErrorScreen msg={`O valor mínimo do pedido é de ${toBRL(rule.params.min)}`} handleClick={handleClick} />
                    break;
                default:
                    console.error("This action doesn't exists")
                    break;
            }
            return null
        }).filter((e)=> e)

        return invalidItems
    }
}

export default RulesValidation