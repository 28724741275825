const Etiqueta = ({ left, top, icone })=> {
    return (

        <img style={{
            position: "absolute",
            left: left,
            top: top,
            width: "initial",
            height: "initial"
        }} 
            src={`../../assets/catalog/${icone}.jpg`} 
            alt="etiqueta"
        />

    )
}

export default Etiqueta;