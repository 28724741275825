import { toBRL } from "./currencyFormatter";
import isEmpty from "./isEmpty";

export default function orderToString(cartItems) {
    const BR = "%0D%0A"; // Código De quebra de linha para enviar a mensagem do pedido pelo zap
    const total = cartItems.reduce((total, item) => total + (item.price * item.quantity), 0)
    let str = ''
    if (!isEmpty(cartItems)) {    
        cartItems.forEach((item) => {
            str += `Código: ${item.reference} ${BR}` +
            `${ item.code ? `Ref.: ${item.code} ${BR}` : ""}` +
            `Qtd: ${item.quantity} ${BR}` +
            `Descrição: *${item.description}* ${BR}` +
            `${ item.color ? `Cor: ${item.color} ${BR}` : ""}` +
            `${ item.size ? `Tamanho: ${item.size} ${BR}` : ""}` +
            `Pág.: ${item.page} ${BR}` +
            `Preço unitário: ${toBRL(item.price * 1)}${BR}` +
            `Total: *${toBRL(item.quantity * item.price)}* ${BR}${BR}`
        });
        str += `${BR}*Total do Pedido: ${toBRL(total)}*`
        return str
    }
}