export const addToCart = (item) => ({
    type: 'ADD_TO_CART',
    payload: item,
});

export const updateQuantity = (id, code, color, size, quantity) => ({
    type: 'UPDATE_QUANTITY',
    payload: { id, code, color, size, quantity },
});

export const removeFromCart = (id, reference, size, color) => ({
    type: 'REMOVE_FROM_CART',
    payload: { id, reference, size, color },
});

export const clearCart = () => ({
    type: "CLEAR_CART",
});