import React, { useEffect, useState } from "react";
import { UseUserContext } from "../../context/UserContext";


const FooterProductModalWithRules = ({AllIsSelected, handleChangeQuantity, quantity, getCartProduct, setProductModal, addToCart, clear, productModal})=> {
    const [tempCart, setTempCart] = useState([])
    const {rules} = UseUserContext().variaveisFornecedor

    const min = rules.productRules.find((rule)=> rule.action === "CHECK_QUANTITY_OF_EACH_ITEM").params.min

    const validateQuantity = ()=> min <= getTotal()

    const chooseProducts = ()=> {
        if (AllIsSelected()) {
            const cartProduct = getCartProduct()
            setTempCart([...tempCart, cartProduct])
            clear()
            console.log(quantity)
        }
    }

    const getTotal = ()=> {
        if (Array.isArray(tempCart) && tempCart.length > 0) {
            let total = 0
            tempCart.forEach((item)=> {
                total += Number(item.quantity)
            })
            return total
        }
        return 0
    }

    const handleProductButton = () => {
        if(validateQuantity()) {
          setProductModal(false);
          tempCart.forEach(item => {
            addToCart(item)
          });
        }
      };

      useEffect(() => {
        if (productModal) {
            setTempCart([])
        }
      }, [productModal]);
    
    return (
        <div className="modal-pro-footer">
            <div>
                <button className={(AllIsSelected()) ? "allowed-button" : ""} onClick={chooseProducts}>Adicionar</button>
                <div className="kapo-quantityitem">
                <button className="kapo-qtybtn" onClick={() => handleChangeQuantity(-1)}>_</button>
                <span className="kapo-qtd">{quantity}</span>
                <button className="kapo-qtybtn" onClick={() => handleChangeQuantity(1)}>+</button>
                </div>
            </div>
            <button className={validateQuantity() > 0 ? "allowed-button" : ""} onClick={handleProductButton}>{getTotal() > 2 ? `Adicionar ${getTotal()} produtos ao carrinho` : `Adicione mais ${(min - getTotal())} produtos`}</button>
        </div>
    )
}

export default FooterProductModalWithRules