import "../../assets/css/component/presentation/pin-animations.css"

const DEFAULT_SIZE = "24px"

const PinButton = ({ left, top, size = DEFAULT_SIZE, animation, icone, handleClick, area })=> {
    if (!area) return
    
    return (
        <button 
            className={"absolute rounded-full"}
            data-animation={animation}
            style={{
                width: size,
                height: size,
                left: left,
                top: top
            }} 
            onClick={handleClick}
        >
            <img
                className={"rounded-full"}
                src={icone} 
                alt="icon-modal"
                style={{ width: size, height: size }}
            />
        </button>
    )
}

export default PinButton;