import React, { useState, createContext, useContext } from "react";

const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
  const [menu, setMenu] = useState(false);
  const [search, setSearch] = useState(false);
  const [cart, setCart] = useState(false);
  const [share, setShare] = useState(false);
  const [pagecnt, setPagecnt]= useState(0);

  const modals = {
    menu: menu,
    setMenu: setMenu,
    search: search,
    setSearch: setSearch,
    cart: cart,
    setCart: setCart,
    share: share,
    setShare: setShare,
    pagecnt: pagecnt,
    setPagecnt: setPagecnt,
  };

  return (
    <ModalContext.Provider value={modals}>{children}</ModalContext.Provider>
  );
};

export const UseModalContext = () => useContext(ModalContext);
