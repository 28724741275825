//https://www.freecodecamp.org/news/react-helmet-examples/

//carregamento inicial da imagem no whatsapp
//https://stackoverflow.com/questions/76789243/react-whatsapp-preview-different-title-img-description-for-different-pages

//https://stackoverflow.com/questions/19778620/provide-an-image-for-whatsapp-link-sharing 
import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function SEO({type,dados}) {

return (
<Helmet>
{ /* Standard metadata tags */ }
<title>{dados.titulo_pagina}</title>
<meta name='description' content={dados.descricao_pagina} />
<meta name='keywords' content={dados.palavras_chave} />
{ /* End standard metadata tags */ }
{ /* Facebook tags */ }
<meta property="og:type" content={type} />
<meta property="og:title" content={dados.titulo_pagina} />
<meta property="og:description" content={dados.descricao_pagina} />
<meta property="og:url" content={window.location.href.split('?')[0]} />
<meta property="og:site_name" content={dados.nome} />
<meta property="og:image" itemProp="image" content="https://useminari.b-cdn.net/abertura.jpg" />
<meta property="og:image:type" content="image/png" />
<meta property="og:image:width" content="600" />
<meta property="og:image:height" content="600" />
{ /* End Facebook tags */ }

</Helmet>
)
}