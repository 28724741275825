import React from "react";
import "../../assets/css/component/modal/shareModal.css";
import { CSSTransition } from "react-transition-group";
import { UseModalContext } from "../../context/UseModalContext";
import { UseUserContext } from "../../context/UserContext";

import modalWhatsapp from "../../assets/img/icone-whatsapp.svg";
import modalFaceBook from "../../assets/img/icone-facebook.svg";
import CloseButton from "../presentation/CloseButton";

const ShareModal = () => {
  const { share, setShare } = UseModalContext();
  const { link_whats, link_face } = UseUserContext().variaveisFornecedor;
  const handleShareModal = () => {
    setShare(false);
  };
  return (
    <CSSTransition in={share} unmountOnExit timeout={{ enter: 0, exit: 1000 }}>
      <div className="modal-sh" onClick={handleShareModal}>
        <div className="modal-sh-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-sh-header">
            <div className="modal-sh-title">
              <span>Gostou dos nossos produtos?</span>
            </div>
            <CloseButton handleClick={handleShareModal}/>
          </div>
          <div className="modal-sh-body">
            <h3>
              Compartilhe essa revista pelo Whatsapp ou Facebook com seus amigos
              e amigas!
            </h3>
            <div className="modal-sh-body-elements">
              <a id="botao-whatsapp" target="_blank" rel="noreferrer" href={link_whats}>
                <img src={modalWhatsapp} aria-hidden="true" alt="Whatsapp" />
              </a>
              <a id="botao-facebook" target="_blank" rel="noreferrer" href={link_face}>
                <img src={modalFaceBook} aria-hidden="true" alt="Facebook" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default ShareModal;
