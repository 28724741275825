import React, { useEffect, useState } from "react";
import { UseModalContext } from "../context/UseModalContext";
import { UseCntContext } from "../context/UseCntContext";
import "../assets/css/component/header.css";
import MenuIcon from "../assets/img/indice-menu.svg";
import ShareIcon from "../assets/img/icone-compartilhar.png";
import SearchIcon from "../assets/img/icone-buscar.png";
import IconTotal from "../assets/img/icone_total.png";
import IconGostart from "../assets/img/gostart.png";
import { isMobile } from 'react-device-detect';
import { toBRL } from "./utils/currencyFormatter";

import { connect } from 'react-redux';
import { UseUserContext } from "../context/UserContext";

const Header = ({cartItems}) => {
  const { setMenu, setSearch, setCart, setShare, setPagecnt } = UseModalContext();
  const {cnt, setCnt} = UseCntContext();
  const {variaveisFornecedor} = UseUserContext();
  const [showForm, setShowForm] = useState(false);
  const [animate, setAnimate] = useState(false)

  useEffect(()=> {
    if (cartItems.length) {
      setAnimate(true)
      setTimeout(()=>setAnimate(false), 700)
    }
  }, [cartItems])

  var imageCount = variaveisFornecedor.qtdpaginas;

  const calculateTotalQuantity = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  const calculateTotalPrice = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleClick = async () => {
    await setShowForm(!showForm);
    document.querySelector(".kapo-overlay").style.display = "block";
    setTimeout(() => {
      document.querySelector(".kapo-overlay").style.opacity = "0.2";
    }, 50);
    document.querySelector(".kapo_cntbtn").focus();
  };

  const handleClickOver = () => {
    setShowForm(false);
    document.querySelector(".kapo-overlay").style.opacity = "0";
    setTimeout(() => {
      document.querySelector(".kapo-overlay").style.display = "none";
    }, 400);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setPagecnt(event.target.value);
      setShowForm(false);
      document.querySelector(".kapo-overlay").style.opacity = "0";
      setTimeout(() => {
        document.querySelector(".kapo-overlay").style.display = "none";
      }, 400);
      event.preventDefault();
    }
  };

  const handleInitScreen = () => {
    setPagecnt(1);
    setCnt(1);
  }


  return (
    <div className="sticky top-0 w-full h-9 mt-1">
      <div className="flex mx-auto kh-width justify-between h-full">
        <div className="flex w-1/3 items-center">
          <div
            className="w-5 h-5 kh-menu cursor-pointer"
            onClick={() => setMenu((menu) => true)}
          >
            <img src={MenuIcon} alt="Menu" />
          </div>
          <div
            className="w-5 h-5 kh-share ml-5 cursor-pointer"
            onClick={() => setShare((share) => true)}
          >
            <img src={ShareIcon} alt="Share" />
          </div>
          <div
            className="w-5 h-5 kh-search ml-5 cursor-pointer"
            onClick={() => setSearch((search) => true)}
          >
            <img src={SearchIcon} alt="Share" />
          </div>
          <div
            className="w-5 h-5 kh-go ml-5 cursor-pointer"
            onClick={handleInitScreen}
          >
            <img src={IconGostart} alt="Share" />
          </div>
        </div>
        <div className="flex w-1/3 items-center justify-center max-[350px]:hidden">
          {showForm ? (
            <input
              className="kapo_cntbtn"
              type="number"
              required
              title="Por favor, digite um número"
              min="1"
              max="999"
              placeholder="Pag Nº"
              onKeyUp={handleKeyPress}
            />
          ) : (
            isMobile ? 
              <p onClick={handleClick}>
                Página <span>{cnt}</span> de {imageCount}
              </p>
            : 
              <p onClick={handleClick}>
                Página <span>{(cnt-1)*2 + 1}{(cnt-1)*2+2 <= imageCount ? `/${(cnt-1)*2+2}` : null}</span> de {imageCount}
              </p>
          )}
          <div onClick={handleClickOver} className="kapo-overlay"></div>
        </div>
        <div className="flex w-1/3 items-center justify-end">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setCart((checkout) => true)}
          >
            <div className={animate ? " animate-wiggle" : ""}>
              <div className={"flex w-5 h-5 rounded-full bg-[#875a85] absolute -mt-2 -ml-2 items-center justify-center"}>
                <div className="text-white text-[13px]">{calculateTotalQuantity()}</div>
              </div>
              <div className={"w-5 h-5"}>
                <img src={IconTotal} alt="checkout"/>
              </div>
            </div>
            <p className="ml-2 kh-checkout text-[#686868] font-['Roboto, sans-serif'] ">
              {toBRL(calculateTotalPrice())}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  cartItems: state.cart.cartItems,
});
export default connect(mapStateToProps,{})(Header);