const SizeButton = ({ size, selectedSize, onSelect })=> {
    
    const isSelected = selectedSize === size;

    return (
        <div
            className={` w-10 h-10 rounded-full flex justify-center items-center border border-black cursor-pointer ${isSelected ? 'bg-blue-500' : 'bg-gray-200'}`}
            onClick={() => onSelect(size)}
        >
            {size}
        </div>
    );
}

export default SizeButton;