import "../../assets/css/component/presentation/loader.css"

const Loader = ({text})=> {
    return ( 
        <div className="loader">
            <div></div>
            <p>{text}</p>
        </div>
    );
}

export default Loader;