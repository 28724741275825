import React from "react";
import "../../assets/css/component/modal/menuModal.css";
import { CSSTransition } from "react-transition-group";
import { UseModalContext } from "../../context/UseModalContext";
import { UseCntContext } from "../../context/UseCntContext";

import modalView from "../../assets/img/icone_tela_cheia.png";
import modalArrow from "../../assets/img/seta-indice.svg";
import modalIndex from "../../assets/img/icone_indice.png";
import modalWhatsapp from "../../assets/img/icone-whats2.png";
import modalEmail from "../../assets/img/icone-email.png";
import CloseButton from "../presentation/CloseButton";

const MenuModal = (props) => {
  const { elementRef } = props;
  const { menu, setMenu, setPagecnt } = UseModalContext();
  const {setCnt} = UseCntContext();

  const handleMenuModal = () => {
    setMenu(false);
  };

  const handleFullScreen = () => {
    if (elementRef.current) {
      if (elementRef.current.requestFullscreen) {
        elementRef.current.requestFullscreen();
      } else if (elementRef.current.mozRequestFullScreen) { // Firefox
        elementRef.current.mozRequestFullScreen();
      } else if (elementRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
        elementRef.current.webkitRequestFullscreen();
      } else if (elementRef.current.msRequestFullscreen) { // IE/Edge
        elementRef.current.msRequestFullscreen();
      }
    }

    setMenu(false);
  };

  const handleInitScreen = () => {
    setPagecnt(1);
    setCnt(1);
    setMenu(false);
  }

  return (
    <CSSTransition in={menu} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
      <div className="modal-m" onClick={handleMenuModal} >
        <div className="modal-m-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-m-header">
            <div className="modal-m-title">
              <span>selecione a opção desejada</span>
            </div>
            <CloseButton handleClick={handleMenuModal}/>
          </div>
          <div className="modal-m-body">
            <div className="modal-m-body-view">
              <img src={modalView} alt="menu-modal" aria-hidden={true} />
              <h5>Modo de exibição</h5>
            </div>

            <ul className="modal-m-body-view-mode">
              <li>
                <button onClick={handleFullScreen}>
                  <span id="texto-modo-exibicao">Tela Cheia</span>{" "}
                  <img src={modalArrow} alt="arrow" aria-hidden={true} />
                </button>
              </li>
            </ul>

            <div className="modal-m-body-view">
              <img src={modalIndex} alt="view" aria-hidden={true} />
              <h5>Índice</h5>
            </div>
            <ul className="modal-m-body-view-mode">
              <li>
                <button data-value="tela-cheia" onClick={handleInitScreen}>
                  <span id="texto-modo-exibicao">Capa</span>{" "}
                  <img src={modalArrow} alt="arrow" aria-hidden={true} />
                </button>
              </li>
            </ul>
          </div>
          <div className="modal-m-footer">
            <h3>CONTATO</h3>
            <ul>
              <li>
                <a
                  href="https://api.whatsapp.com/send?phone=5521979040595&text=Ol%C3%A1%2C%20gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%20os%20cat%C3%A1logos"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={modalWhatsapp} alt="Whatsapp" />
                  <p>Zap de dúvidas</p>
                </a>
              </li>
              <li>
                <a href="mailto:suporte@ecfacil.com.br" target="_blank" rel="noreferrer">
                  <img src={modalEmail} alt="Email" />
                  <p>suporte@ecfacil.com.br</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default MenuModal;
