import React from "react";
import {createBrowserRouter} from "react-router-dom"
import Error404Page from "./component/error/Error404Page";
import Home from "./Home"
import Main from "./component/Main"

const Router = createBrowserRouter([
    {
        path: "/Erro404",
        element: <Error404Page/>, 
        errorElement: <Error404Page/>
    },
    {
        path: "*",
        element: <Home/>,
        children: [
            {
                path: "*",
                element: <Main/>
            },
            {
                path: "",
                element: <Main/>
            },
        ],       
        errorElement: <Error404Page/>
    },
    {
        path: "",
        element: <Home/>,
        children: [
            {
                path: "",
                element: <Main/>
            },
            {
                path: "*",
                element: <Main/>
            }
        ],       
        errorElement: <Error404Page/>
    },
])

export default Router