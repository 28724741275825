class Icons {
    constructor(url) {
        this.url = url + "/icons"
        this.favicon = this.url + "/favicon.svg"
    }
    getPin = (pinName)=> this.url + `/${pinName}`
}

class Pages {
    constructor(url, catalog_id) {
        this.catalog_id = catalog_id
        this.url = url + `/${catalog_id}/paginas`
    }
    
    getPage = (pageNumber)=> this.url + `/${pageNumber}.webp`
}

class CDN {
    constructor(id) {
        this.id = id
        this.url = `https://useminari.b-cdn.net/${this.id}`
        this.icons = new Icons(this.url)
    }
    pages = (catalog_id)=> new Pages(this.url, catalog_id)
}

export {CDN as default, Icons, Pages}